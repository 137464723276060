import { useTranslation } from 'next-i18next';

import { DECIMAL_PLACES } from '@/core/constants/decimalPlaces';
import { CENTS_PER_UNIT } from '@/core/constants/price';

export const FormattedPrice = ({
  centAmount,
  currencyCode,
  hideDecimals = false,
}: {
  centAmount?: number;
  currencyCode?: string;
  hideDecimals?: boolean;
}) => {
  const { t, i18n } = useTranslation('product');

  const transformCentAmountToCurrencyFormat = (
    centAmount: number,
    currencyCode: string
  ) =>
    Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: currencyCode,
      ...(hideDecimals && {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }),
    }).format(Number(parseCentAmount(centAmount)));

  if (centAmount === undefined || !currencyCode) return <>{t('N/A')}</>;

  return <>{transformCentAmountToCurrencyFormat(centAmount, currencyCode)}</>;
};

const parseCentAmount = (centAmount: number) =>
  (centAmount / CENTS_PER_UNIT).toFixed(DECIMAL_PLACES);
