import { useCallback } from 'react';

import { useShippingAddress } from '@/core/hooks/useShippingAddress';
import { ShippingAddressInput } from '@/types/gqlCommon.delio.generated';
import { updateDeep } from '@lib/api/update-deep';

import { useCreateCurrentCartMutation } from '../graphql/mutations/CreateCurrentCart/CreateCurrentCart.delio.generated';
import {
  CurrentCartQuery,
  useCurrentCartQuery,
} from '../graphql/queries/CurrentCart/CurrentCart.delio.generated';

export const useRefreshOrCreateNewCart = () => {
  const currentCartResult = useCurrentCartQuery({ skip: true });
  const [createCart] = useCreateCurrentCartMutation();
  const { data: shippingAddress } = useShippingAddress();

  const refreshOrCreateNewCart = async () => {
    // check if cart exists.
    // if exist then update cart cache
    // if not exist then create new cart and update cart cache

    const cart = await currentCartResult.refetch();
    if (shippingAddress && !cart) {
      const newCart = await createNewCart(shippingAddress);

      if (!newCart) return;

      updateCartCache(newCart);
    } else {
      updateCartCache(cart.data.currentCart);
    }
  };

  const updateCartCache = useCallback(
    (currentCart: CurrentCartQuery['currentCart']) => {
      currentCartResult.updateQuery((prev) =>
        updateDeep(prev, { currentCart })
      );
    },
    [currentCartResult]
  );

  const createNewCart = useCallback(
    async (
      cartShippingAddress: ShippingAddressInput
    ): Promise<CurrentCartQuery['currentCart'] | undefined> => {
      const newCurrentCart = await createCart({
        variables: {
          cart: { shippingAddress: cartShippingAddress },
        },
      });

      return newCurrentCart?.data?.createCart;
    },
    [createCart]
  );

  return refreshOrCreateNewCart;
};
