import { captureException } from '@sentry/nextjs';
// eslint-disable-next-line no-restricted-imports
import { type ClassValue, clsx } from 'clsx';

import { logger } from '@/config/logger';
import { twMerge } from '@/core/services/twMerge';

export const showChat = () => {
  // Freshdesk is a 3rd party dependency that we don't control,
  // hence we need to bend some rules when we're using it.

  if (typeof window !== 'undefined' && window?.fcWidget) {
    window.fcWidget.show();
    window.fcWidget.open();
    return;
  }

  const err = new Error(
    'Cannot open the Freshdesk widget. Freshdesk not available in window'
  );
  captureException(err);
  logger.error(err);
};

export const clearChatUser = () => {
  if (typeof window !== 'undefined' && window?.fcWidget) {
    window.fcWidget.user.clear();
    return;
  }

  const err = new Error(
    'Cannot clear the Freshdesk user. Freshdesk not available in window'
  );
  captureException(err);
  logger.error(err);
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
