/**
 * Obtain desired image variant, select one of:
 * - thumb (50x50)
 * - small (150x150)
 * - medium (400x400)
 * - large (700x700)
 * - zoom (1400x1400)
 *
 * More info: https://docs.commercetools.com/api/projects/products#images
 */
export const withImageSize = (imageUrl: string, imageSize: ImageSize) =>
  imageUrl.replace(
    extensionRegExp,
    (_: string, urlWithoutExtension: string, extension: string) =>
      `${urlWithoutExtension}-${imageSize}.${extension}`
  );

const extensionRegExp = /(.{1,})\.([A-z]{1,})$/gm;

export type ImageSize = 'thumb' | 'small' | 'medium' | 'large' | 'zoom';
