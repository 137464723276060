import { extendTailwindMerge } from 'tailwind-merge';

import { config } from '@/core/ui/config/tailwindModern.present';

const theme = config?.theme?.extend;
const fontSize = theme?.fontSize ?? {};
const boxShadow = theme?.boxShadow ?? {};
const animation = theme?.animation ?? {};
const dropShadow = theme?.dropShadow ?? {};

export const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': Object.keys(fontSize).map((key) => `text-${key}`),
      shadow: Object.keys(boxShadow).map((key) => `shadow-${key}`),
      animate: Object.keys(animation).map((key) => `animate-${key}`),
      'drop-shadow': Object.keys(dropShadow).map((key) => `drop-shadow-${key}`),
    },
  },
});
