import { LineItem } from '@/core/types';
import { calculateMultiBuyTotalPrice } from '@/modules/cart/services/calculateMultiBuyTotalPrice';

export const selectCartItemTotal = (lineItems: Readonly<LineItem[]>) =>
  lineItems.reduce((total, { quantity, product: { price } }) => {
    const centAmount = price?.discounted
      ? // `discounted.value` is a price of a product that has its specific discount.
        // Do not mislead it with whole cart's discount.
        price.discounted.value.centAmount
      : price.value.centAmount;

    const multiBuyTotalPrice = price?.multibuy
      ? calculateMultiBuyTotalPrice({
          quantity,
          multiBuyTrigger: price.multibuy.triggerQuantity,
          multiBuyPrice: price.multibuy.value?.centAmount,
          itemRegularPrice: centAmount,
          maxMultibuyQuantity: price?.multibuy?.maxQuantity,
        })
      : undefined;

    const productPrice = multiBuyTotalPrice || quantity * centAmount;

    return total + productPrice;
  }, 0);
