export const calculateMultiBuyTotalPrice = ({
  quantity,
  multiBuyTrigger,
  multiBuyPrice,
  itemRegularPrice,
  maxMultibuyQuantity,
}: {
  quantity: number;
  multiBuyTrigger: number;
  multiBuyPrice: number;
  itemRegularPrice: number;
  maxMultibuyQuantity?: number | null;
}) => {
  const exceededMultibuyQuantity = maxMultibuyQuantity
    ? Math.max(quantity - maxMultibuyQuantity, 0)
    : 0;
  const multibuyQuantity = quantity - exceededMultibuyQuantity;
  const basePriceMultibuyQuantity = multibuyQuantity % multiBuyTrigger;
  const basePriceQuantity =
    exceededMultibuyQuantity + basePriceMultibuyQuantity;
  const multibuyPriceQuantity = Math.floor(multibuyQuantity / multiBuyTrigger);

  return (
    basePriceQuantity * itemRegularPrice + multibuyPriceQuantity * multiBuyPrice
  );
};
