import { cn } from '@/core/ui/utils';
import {
  CSSProperties,
  FunctionComponent,
  memo,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';

import { useIsNativeLazyLoadingSupported } from '../hooks/useIsNativeLazyLoadingSupported';

import { ImgWrapper, ImgWrapperProps } from './ImgWrapper';
import { NO_JS_CLASS_NAME } from './NoJsImageStyle';

export const Img: FunctionComponent<ImgProps> = memo((props) => {
  const hasCache = loadedImagesCache[props.src];
  const [status, setStatus] = useState<
    'idle' | 'pending' | 'resolved' | 'rejected'
  >(hasCache ? 'resolved' : 'pending');

  const isActive = props.isActive ?? true;

  const isNativeLazyLoadingSupported = useIsNativeLazyLoadingSupported();

  const imageRef = useRef<HTMLImageElement | null>(null);

  const [setImgInViewRef, isImgInViewport] = useInView({
    trackVisibility: true,
    delay: 300,
    threshold: 0,
    rootMargin: props.viewportDetectionMargin ?? '200px 50px',
    fallbackInView: true,
    skip: hasCache || isNativeLazyLoadingSupported,
  });

  const isImgReadyToLoad =
    hasCache || (isActive && (isImgInViewport || isNativeLazyLoadingSupported));

  useEffect(() => {
    if (hasCache) return;
    loadedImagesCache[props.src] = isImgReadyToLoad;
  }, [isImgReadyToLoad, hasCache, props.src]);

  const srcSet = Object.entries(props.srcSetObj ?? {})
    .filter(([, src]) => src)
    .map(([size, src]) => `${src} ${size}`)
    .join(', ');

  const baseImgStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    objectFit: props.imageFit ?? 'contain',
    objectPosition: props.imagePosition,
    width: '100%',
    height: '100%',
  } as const;

  const commonProps = {
    onLoadStart: () => isImgReadyToLoad && setStatus('pending'),
    onLoad: () => {
      props.onLoad?.();
      return isImgReadyToLoad && setStatus('resolved');
    },
    onError: () => setStatus('rejected'),
    width: props.width,
    height: props.height,
    alt: props.alt,
    style: {
      ...baseImgStyle,
      visibility:
        status !== 'resolved' && status !== 'rejected' && props.placeholder
          ? 'hidden'
          : 'visible',
    },
  } as const;

  const idleProps = {
    src:
      typeof props.placeholder === 'string'
        ? props.placeholder
        : transparentPixelBase64,
  };

  const readyProps = {
    src: props.src,
    srcSet: srcSet ?? undefined,
  };

  const imgWrapperProps = {
    className: cn(props.className, NO_JS_CLASS_NAME),
    width: props.width,
    height: props.height,
    placeholder: status === 'pending' ? props.placeholder : undefined,
    fallback: status === 'rejected' ? props.fallback : undefined,
    customizeStyle: props.customizeStyle,
  };

  useEffect(() => {
    if (!imageRef?.current?.complete) return;

    // in isomorphic rendered page image can be downloaded before main script.js file.
    // So image can be already loaded before react register onLoad event.
    setStatus('resolved');
    props.onLoad?.();
  }, [props]);

  const setImageRefs = (img: HTMLImageElement) => {
    imageRef.current = img;

    return setImgInViewRef(img);
  };

  const noScript = (
    <noscript>
      <ImgWrapper
        className={props.className}
        width={props.width}
        height={props.height}
      >
        <img
          width={props.width}
          height={props.height}
          alt={props.alt}
          style={baseImgStyle}
          {...readyProps}
        />
      </ImgWrapper>
    </noscript>
  );

  return (
    <>
      {noScript}
      <ImgWrapper {...imgWrapperProps}>
        <img
          {...commonProps}
          {...(isImgReadyToLoad ? readyProps : idleProps)}
          ref={setImageRefs}
          loading={props.loading || 'lazy'}
          decoding="async"
          fetchPriority={props.fetchPriority || 'auto'}
        />
      </ImgWrapper>
    </>
  );
});

export type ImgProps = {
  /**
   * It should be intrinsic width of the image
   * It's necessary to calculate width/height ratio and avoid layout shift.
   *
   * **IT SHOULD NEVER BE AN OPTIONAL PROP!**
   */
  width: number;
  /**
   * It should be intrinsic height of the image.
   * It's necessary to calculate width/height ratio and avoid layout shift.
   *
   * **IT SHOULD NEVER BE AN OPTIONAL PROP!**
   */
  height: number;
  /**
   * Equivalent to the `src` from `<img>`
   */
  src: string;
  /**
   * Almost all images needs `alt`. If the image does not bring any information
   * you can use `alt=""`
   */
  alt: string;
  /**
   * It won't applied directly to the `<img>`, but `<img>` should inherit all of
   * the relevant CSS properties.
   */
  className?: string;
  /** the img's `srcSet` attribute, but formatted as a JS object  */
  srcSetObj?: {
    '2x'?: string;
    '2.5x'?: string;
    '3x'?: string;
    '3.5x'?: string;
    '4x'?: string;
    '4.5x'?: string;
  };
  /** Data URI (base64) or a react element for a placeholder during the image load */
  placeholder?: string | ReactNode;
  /** A react element for a fallback if there was an error during the image load */
  fallback?: ReactNode;
  /** If `isActive == false` the image won't load */
  isActive?: boolean;
  /**
   * Imaginary margin around the element.
   *
   * It's useful when you'd like to make the element detectable in the viewport,
   * before it actually goes to the viewport.
   *
   * Can have values similar to the CSS margin property, e.g. `10px 20px 30px 40px`
   */
  viewportDetectionMargin?: string;
  /**
   * You should not override the default `style` of the component, but you can
   * use this prop to customize them as you wish.
   */
  customizeStyle?: ImgWrapperProps['customizeStyle'];
  /**
   * Customize how the image is displayed if the displayed size does not match
   * image's intrinsic size (https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit)
   */
  imageFit?: CSSProperties['objectFit'];
  /**
   * Customize how the image is displayed if the displayed size does not match
   * image's intrinsic size (https://developer.mozilla.org/en-US/docs/Web/CSS/object-position)
   */
  imagePosition?: CSSProperties['objectPosition'];
  onLoad?: () => void;

  loading?: HTMLImageElement['loading'];

  /**
   * An attribute representing the priority hint. (experimental feature - older browser will ignore the attribute)
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/fetchPriority
   */
  fetchPriority?: 'high' | 'low' | 'auto';
};

/**
 * If the given src for an images was loaded once, we should not make it go
 * through the lazy-load flow again.
 *
 * It only works on the client-side.
 */
const loadedImagesCache: Record<string, boolean> = {};

/**
 * An image that consist a transparent pixel (1x1), that we may use as fallback
 * until the image loads.
 */
const transparentPixelBase64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
