import { useEffect, useState } from 'react';

/**
 * Detects if the browser supports the `loading` attribute on the `<img />`
 * elements, since it's a new feature (https://caniuse.com/?search=loading).
 *
 * If the user's browser support the lazy loading natively, we don't have to
 * load the image programmatically.
 *
 * More here: https://web.dev/browser-level-image-lazy-loading
 * and here https://github.com/thebuilder/react-intersection-observer/blob/HEAD/docs/Recipes.md#lazy-image-load
 */
export const useIsNativeLazyLoadingSupported = () => {
  const [isNativeLazyLoadingSupported, setIsNativeLazyLoadingSupported] =
    useState<boolean>(() => initialIsNativeLazyLoadingSupported);

  useEffect(() => {
    initialIsNativeLazyLoadingSupported = true;
    setIsNativeLazyLoadingSupported('loading' in HTMLImageElement.prototype);
  }, []);

  return isNativeLazyLoadingSupported;
};

// Keep outside the hook so we can memoize the value on the client-side
let initialIsNativeLazyLoadingSupported = false;
