import { captureException } from '@sentry/nextjs';

import { IMAGEKIT_URL } from '@/config/consts';
import { logger } from '@/config/logger';

// Parameters:
// Docs: https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations
// w - Width 0 - Infinity px | 0.1 - 1 percent
// h - Height 0 - Infinity px | 0.1 - 1 percent
// q - Quality 0 - 100
// dpr - DPR 0.1 - 5
export type GetImageKitUrlProps = {
  url: string;
  params: {
    w?: number;
    h?: number;
    q?: number;
    dpr?: number;
    cm?: string;
  };
};

export const getImageKitUrl = ({ url, params }: GetImageKitUrlProps) => {
  try {
    const { pathname } = new URL(url);
    const paramsQuery = Object.entries(params)
      .map(([key, val]) => `${key}-${val}`)
      .join(',');

    return `${IMAGEKIT_URL}${pathname}?tr=${paramsQuery}`;
  } catch (err) {
    logger.error(err);
    captureException(err);
  }
  return '';
};
