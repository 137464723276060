import { ApolloError } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { useRouter } from 'next/router';

import {
  UpdateCurrentCartMutation,
  UpdateCurrentCartMutationVariables,
  // eslint-disable-next-line no-restricted-imports
  useUpdateCurrentCartMutation as useNativeUpdateCurrentCartMutation,
} from '../graphql/mutations/UpdateCurrentCart/UpdateCurrentCart.delio.generated';

import { useRefreshOrCreateNewCart } from './useRefreshOrCreateNewCart';

export const useUpdateCurrentCartMutation = (
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentCartMutation,
    UpdateCurrentCartMutationVariables
  >
) => {
  const { replace } = useRouter();
  const refreshOrCreateNewCart = useRefreshOrCreateNewCart();

  return useNativeUpdateCurrentCartMutation({
    ...baseOptions,
    onError: async (error) => {
      if (error instanceof ApolloError) {
        const [err] = error.graphQLErrors;
        if (err?.extensions?.code === 'CART_NOT_ACTIVE') {
          await refreshOrCreateNewCart();
          await replace('/');
          return;
        }
      }
      baseOptions?.onError?.(error);
    },
  });
};
