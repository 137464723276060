import {
  FC,
  FunctionComponent,
  HTMLProps,
  ReactElement,
  SVGAttributes,
  SVGProps,
  useId,
} from 'react';

type Svg = FC<
  SVGProps<SVGSVGElement> & {
    ['aria-hidden']?: true;
  }
>;
/**
 * This component helps us providing the title and description for the SVG
 * graphics, to make the app more accessible.
 *
 * More info about accessible SVG might be found below:
 * https://css-tricks.com/accessible-svgs/#2-inline-svg
 * */
export const AccessibleSvg: FunctionComponent<{
  title?: string;
  SvgComponent: Svg;
  className?: string;
  onClick?: HTMLProps<SVGElement>['onClick'];
  testId?: string;
}> = ({ title, SvgComponent, className, onClick, testId }) => {
  const uidSeed = useId();
  const titleId = `title:${uidSeed}-${SvgComponent.name}`;

  const originalSvg = SvgComponent({}) as ReactElement<
    SVGAttributes<SVGElement>
  >;

  const {
    children,
    className: originalClassName,
    ...props
  } = originalSvg.props;

  return (
    <svg
      className={className || originalClassName}
      role="img"
      aria-labelledby={titleId}
      onClick={onClick}
      {...props}
      data-testid={testId}
    >
      <title id={titleId}>{title}</title>
      {children}
    </svg>
  );
};
