import { memo } from 'react';

import ProductImageFallback from '@/core/images/product-image-placeholder.svg';
import { getImageKitUrl } from '@/core/transformers/getImageKitUrl';
import { FC } from '@/types/common';
import { ImageSize, withImageSize } from '@lib/api/with-image-size';
import { Img } from '@lib/img/components/Img';

import { Skeleton } from '../Skeleton/Skeleton';

const PRODUCT_TILE_SIZE = 96;

export const ProductImage: FC<ProductImageProps> = memo(
  ({ alt, url, height, width, size }) => {
    const originSrc = withImageSize(url ?? '', size || 'medium');

    return (
      <Img
        fallback={
          <ProductImageFallback aria-hidden className="text-primary-200" />
        }
        placeholder={
          <Skeleton
            width={width || PRODUCT_TILE_SIZE}
            height={height || PRODUCT_TILE_SIZE}
          />
        }
        width={width || PRODUCT_TILE_SIZE}
        height={height || PRODUCT_TILE_SIZE}
        alt={alt}
        src={getImageKitUrl({
          url: originSrc,
          params: {
            w: width || PRODUCT_TILE_SIZE,
            h: height || PRODUCT_TILE_SIZE,
            cm: 'pad_resize',
          },
        })}
        srcSetObj={{
          '2.5x': getImageKitUrl({
            url: originSrc,
            params: {
              w: width || PRODUCT_TILE_SIZE,
              h: height || PRODUCT_TILE_SIZE,
              dpr: 2.5,
              cm: 'pad_resize',
            },
          }),
          '4.5x': getImageKitUrl({
            url: originSrc,
            params: {
              w: width || PRODUCT_TILE_SIZE,
              h: height || PRODUCT_TILE_SIZE,
              dpr: 4.5,
              cm: 'pad_resize',
            },
          }),
        }}
        className="w-full object-scale-down"
      />
    );
  }
);

type ProductImageProps = {
  url?: string;
  url2x?: string;
  alt: string;
  onLoad?: () => void;
  width?: number;
  height?: number;
  size?: ImageSize;
};
