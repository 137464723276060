import { CSSProperties, FunctionComponent, ReactNode } from 'react';

const multiplier = 100;

export const ImgWrapper: FunctionComponent<ImgWrapperProps> = ({
  children,
  width,
  height,
  placeholder,
  className,
  customizeStyle,
  fallback,
}) => {
  const defaultStyle = {
    position: 'relative',
    paddingBottom: `${(height / width) * multiplier}%`,
    // background placeholder is for the native lazy-load
    backgroundImage:
      typeof placeholder === 'string' ? `url(${placeholder})` : undefined,
    backgroundSize: 'cover',
    overflow: 'hidden',
  } as const;

  return (
    <div
      className={className}
      style={customizeStyle ? customizeStyle(defaultStyle) : defaultStyle}
    >
      {typeof placeholder === 'object' || typeof fallback === 'object' ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
          }}
        >
          {fallback ?? placeholder}
        </div>
      ) : null}
      {fallback ? null : children}
    </div>
  );
};

export type ImgWrapperProps = {
  children: ReactNode;
  width: number;
  height: number;
  placeholder?: string | ReactNode;
  fallback?: ReactNode;
  className?: string;
  customizeStyle?: (defaultStyle: DefaultStyle) => CSSProperties;
};

export type DefaultStyle = {
  readonly position: 'relative';
  readonly paddingBottom: `${number}%`;
  readonly backgroundImage: string | undefined;
  readonly backgroundSize: 'cover';
  readonly overflow: 'hidden';
};
